<template>
<section class="content">
			<div class="container-fluid">
				<div class="row">
          <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>

          <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center" v-if="index">
						<div class="card">
							<div class="card-body table-responsive table-head hvertical">
                <div class="row bor-bot">
                                    <div class="col-md-12 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-3 text-left">Template Type</h1>
                                    </div>
                                </div>
                                <div class="d-flex justcontcenter">
                                    <div class="createtemplate">
                                        <img src="/svg/waterski/email.svg"/>
                                        <h6 class="m-0 pt-3 pb-4 fnt-bold"><i>Currently you don't have any Email Template Type</i></h6>
                                        <button type="button" class="btn darkblubtn btn-outline-primary" @click="templateTypeCreate()">Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center" v-if="create">
						<div class="card">
							<div class="card-body table-responsive table-head">
                                <div class="row bor-bot">
                                    <div class="col-md-12 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-3 text-left">Create Email Template Type</h1>
                                    </div>
                                </div>
                                <div class="d-flex justcontcenter shadowbox hvertical">
                                    <div class="createtemplate createform">
                                        <form>
                                            <label>Template Type</label>
                                            <input v-model="templateTypeData.type" maxLength="160" placeholder="Marketing" />
                                          <span class="text-sm text-danger" style="display: block;width: 285px;margin: 0 0 0 auto;text-align: left;" v-if="errors.type">{{
                                              errors.type[0]
                                            }}</span>
                                            <div class="text-right mt-3">
                                                <button type="submit" class="btn btn-secondary mr-3">Cancel</button>
                                                <button type="button" class="btn darkblubtn btn-outline-primary" @click="templateTypeSave">Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> 


                    <section class="col-lg-12 connectedSortable mt-3 pr-0 pl-0 mb-3 text-center " v-if="list">
						<div class="card">
							<div class="card-body table-responsive table-head hvertical">
                                <div class="row bor-bot">
                                    <div class="col-md-8 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-3 text-left">Email Template Types</h1>
                                    </div>
                                    <div class="col-md-4 pt-1 pr-0">
                                        <div class="text-right pb-3">
                                          <ol class="breadcrumb float-sm-right pr-0">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                          </ol>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                  
                                    <div class="col-md-6 mt-3 showentries"> 
                                       <div>
                                        <b-form inline>
                                            <label class="mr-sm-2 font-weight-normal">Show</label>
                                            <b-form-select
                                                id="inline-form-custom-select-pref"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                v-model="perPage"
                                                :options="viewEntryOptions"
                                                size="sm"
                                            ></b-form-select
                                            >entries
                                        </b-form>
                                        </div>
                                    </div>
                                    <div class="col-md-6"> 
                                       <div class="text-right pr-0 emltmpserchsec">
                                            <div class=" card-text d-flex align-items-center float-right"> 
                                            <div class="mt-3 mr-4"> 
                                                <b-input-group size="sm">
                                                    <b-form-input
                                                    id="filter-input"
                                                    v-model="filter"
                                                    type="search"
                                                    placeholder="Search"
                                                    ></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="mt-3 ml-3">
                                                <b-button variant="btn darkblubtn outline-primary" @click="templateTypeCreate()">Add Email Template Type</b-button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                   
                                  <b-table
                                      ref="merchandiseTable"
                                      id="emailteplatlist"
                                      :per-page="perPage"
                                      :current-page="currentPage"
                                      :items="items"
                                      :fields="fields"
                                      class="mt-3"
                                      :filter="filter"
                                      :filter-included-fields="filterOn"
                                      @filtered="onFiltered"
                                      hover
                                      show-empty
                                  >
                                    <template #cell(actions)="row">
                                      <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                          <button
                                              class="btn btn-primary d-none"
                                          >
                                            <i class="fas fa-eye" title="View"></i>
                                          </button>
                                          <button
                                              class="btn btn-primary ml-1"
                                              @click="templateTypeEdit(row.item.id)"
                                          >
                                            <i class="fas fa-pencil-alt" title="Edit"></i>
                                          </button>
                                          <button
                                              class="btn btn-danger ml-1"
                                              @click="templateTypeDelete(row.item.id)"
                                          >
                                            <i class="fas fa-trash" title="Delete"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </template>
                                    <template #empty="scope">
                                      <h4>{{ scope.emptyText }}</h4>
                                    </template>
                                    <template #table-busy>
                                      <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                      </div>
                                    </template>
                                  </b-table>
                                  <br />
                                </div>
                                <div class="row">
                                    <div class="col-md-8 mr-0 pr-0">
                                       

                                    </div>
                                    <div class="col-md-4"> 
                                        <b-pagination
                                      v-model="currentPage"
                                      :total-rows="rows"
                                      :per-page="perPage"
                                      prev-text="Previous"
                                      next-text="Next"
                                      aria-controls="merchandise-table"
                                      class="float-right custom-color"
                                      hide-goto-end-buttons
                                  ></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="col-lg-12 connectedSortable mt-3 pr-0 pl-0 mb-3 text-center" v-if="view">
						<div class="card">
							<div class="card-body table-responsive table-head">
                                <div class="row bor-bot">
                                    <div class="col-md-12 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-3 text-left">Edit Email Template Type</h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 px-0 mt-4">
                                        <form>
                                            <div class="row">
                                                <div class="col-md-1 text-left px-0">
                                                    <label class="linheight-38">Template Type</label>
                                                </div>
                                                <div class="col-md-3 text-left">
                                                    <input class="form-control ml-3" v-model="templateTypeEditData.type" maxLength="100" placeholder="Marketing" />
                                                  <span class="text-sm text-danger ml-3" style="display: block;" v-if="errors.type">{{
                                                      errors.type[0]
                                                    }}</span>
                                                </div>
                                                <div class="col-md-8 text-left">
                                                        <button type="button" class="btn darkblubtn btn-outline-primary ml-4" @click="templateTypeUpdate(templateTypeEditData.id)">Save </button>
                                                       <button type="button" class="btn darkblubtn btn-outline-primary ml-4" @click="templateTypeEditCancel()">Cancel</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="row" v-if="false">
                                    <div class="col-md-12 text-left px-0">
                                        <h1 class="m-0 text-dark pt-4 text-left px-0">Templates List</h1>
                                    </div>
                                   <table id="emailteplatlist" class="table b-table mt-4 table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Template Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                            <td>Marketing</td>
                                            <td>
                                                <div class="btn-group btn-group-sm">
                                                    <button class="btn btn-primary">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                    <button class="btn btn-primary ml-1">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button class="btn btn-danger ml-1">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>Marketing</td>
                                            <td>
                                                <div class="btn-group btn-group-sm">
                                                    <button class="btn btn-primary" @click="viewRow(row.item, row.index, $event.target)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                    <button class="btn btn-primary ml-1" @click="editRow(row.item, $event.target)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>Marketing</td>
                                            <td>
                                                <div class="btn-group btn-group-sm">
                                                    <button class="btn btn-primary" @click="viewRow(row.item, row.index, $event.target)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                    <button class="btn btn-primary ml-1" @click="editRow(row.item, $event.target)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
</section>
</template>
<script>
import TemplateType from './TemplateType.vue';
import axios from 'axios';
import $ from "jquery";
import Errors from "../../Errors.js";
import CustomAlert from "../CustomAlert";
export default {

  name: "TemplateType",
  components:{
    CustomAlert
  },
  data() {
    return {
      index: false,
      create: false,
      list: false,
      view: false,
      templateTypeListData: [],
      templateTypeEditData:{
        type: null
      },
      alertMessage: "",
      displayAlert: "",
      templateTypeData: {
        type: null
      },
      loaded: false,
      perPage: 10,
      currentPage: 1,
      errors: new Errors(),
      fields: [
        {
          label: "Template Type",
          key: "type",
          sortable: true,
        },
        {
          key: "Actions",
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        {value: 5, text: "5"},
        {value: 10, text: "10"},
        {value: 20, text: "20"},
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  methods: {
    templateTypeCreate() {
      this.create = true;
      this.index = false;
      this.list = false;
    },
    templateTypeSave() {

        axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/store', this.templateTypeData
            , {
              headers: {
                "application-id": process.env.VUE_APP_APPLICATION_ID,
                'Content-Type': 'application/json'
              }
            }).then(response => {
          if (response.data.status == 'Success') {
            if (response.data.templateType == '') {
              this.index = true;
            } else {
              this.list = true;
              this.create = false;
              this.errors = new Errors();
              this.templateTypeData.type=null;
              this.alertMessage = "Template Type Created Successfully";
              this.displayAlert = true;
              this.templateTypeList();
            }
          } else {
            console.log(response);
          }
        }).catch(error => {

          this.errors = error.response.data.errors || {};
          console.log(this.errors.type[0]);
        });

    },

    templateTypeList() {
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/getTemplateType',
          {}, {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {

        if (response.data.status == 'Success') {
          if (response.data.templateType == '') {
            this.index = true;
            this.list = false;
          } else {
            this.list = true;
            this.items = response.data.templateType;
            console.log(response.data.templateType);
          }
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

      });
    },

    templateTypeDelete(id) {
      if (confirm("Are you sure to delete this item?")) {
        axios
            .delete(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/templateType/delete/" + id, {
              headers: {
                "application-id": process.env.VUE_APP_APPLICATION_ID,
                'Content-Type': 'application/json'
              }
            })
            .then((response) => {
              this.alertMessage = "Template Type Deleted Successfully";
              this.displayAlert = true;
              this.templateTypeList();
            })
            .catch((error) => {
              (error) => (this.errors = error.response.data);
            });
      }
      return false;
    },
    templateTypeEdit(id) {
      axios.get(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/templateType/edit/" + id, {
        headers: {
          "application-id": process.env.VUE_APP_APPLICATION_ID,
          'Content-Type': 'application/json'
        }
      })
          .then((response) => {
            this.templateTypeEditData=response.data.templateType;
            this.view = true;
            this.list = false;
          })
          .catch((error) => {
            (error) => (this.errors = error.response.data);
          });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    templateTypeUpdate(id){
      axios.patch(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/update/' +id,this.templateTypeEditData
          , {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {
        if (response.data.status == 'Success') {
          if (response.data.templateType == '') {
            this.list = true;
          } else {
            this.list = true;
            this.view = false;
            this.errors = new Errors();
            this.templateTypeData.type=null;
            this.alertMessage = "Template Type Updated Successfully";
            this.displayAlert = true;
            this.templateTypeList();
          }
        } else {
          console.log(response);
        }
      }).catch(error => {

        this.errors = error.response.data.errors || {};
        console.log(this.errors.type[0]);
      });
    },
    resetAlert(){
      this.displayAlert = false;
      this.removeDangerBg();
    },
    templateTypeEditCancel(){
      this.list = true;
      this.view = false;
    }
  },
  beforeMount() {
    this.templateTypeList();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
}
</script>